<template>
  <footer id="footer" class="section-footer">
    <div class="footer">
      <div class="branch-info">
        <nuxt-link :to="PAGE_URLS.HOME" class="logo">
          <BaseImg src="/assets/brand/logo.webp" alt="logo" />
        </nuxt-link>
        <BranchInfo />
      </div>
      <div class="footer-navigation">
        <div class="footer-navigation--info">
          <div v-for="item in NAVIGATIONS" :key="item.title" class="footer-section">
            <p class="section-title">
              {{ item.title }}
            </p>
            <div v-if="item.subCategories.length <= 5">
              <nuxt-link
                v-for="category in item.subCategories"
                :id="category.title"
                :key="category.title"
                class="category-name"
                :to="category.link"
                :target="category?.type === 'BLANK' ? '_blank' : '_self'"
              >
                {{ category.title }}
              </nuxt-link>
              <nuxt-link
                v-if="item.subCategories.length < 2 && $config.SHOW_NEWS !== '0'"
                class="category-name"
                :to="PAGE_URLS.NEWS"
              >
                Tin tức
              </nuxt-link>
            </div>
            <div v-else>
              <div class="row-footer">
                <div class="column">
                  <nuxt-link
                    v-for="category in item.subCategories.slice(0, 4)"
                    :id="category.title"
                    :key="category.title"
                    class="category-name"
                    :to="category.link"
                  >
                    {{ category.title }}
                  </nuxt-link>
                </div>
                <div class="column-right">
                  <nuxt-link
                    v-for="category in item.subCategories.slice(4)"
                    :id="category.title"
                    :key="category.title"
                    class="category-name"
                    :to="category.link"
                  >
                    {{ category.title }}
                  </nuxt-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-deposit">
        <div class="footer-deposit--info container-custom">
          <a
            v-for="item in footerPaymentMethods"
            :id="item.title"
            :key="item.title"
            :href="item?.link || ''"
            @click.prevent="handleClickMethod(item, $event)"
          >
            <div v-if="!isLogged" class="deposit-item">
              <BaseImg class="deposit-image" :src="item.image" :alt="item?.title" />
              <p class="deposit-title">{{ item.title }}</p>
            </div>
            <div v-else-if="isLogged && !item.isMaintain" class="deposit-item">
              <BaseImg class="deposit-image" :src="item.image" :alt="item.title" />
              <p class="deposit-title">{{ item.title }}</p>
            </div>
            <div v-else class="deposit-item" :class="{ maintain: item.isMaintain }">
              <BaseImg class="deposit-image" :src="item.image" :alt="item.title" />
              <p class="deposit-title">{{ item.title }}</p>
              <div v-if="item.isShowTooltip" class="tooltip">
                <div class="tooltip-content">
                  <span class="description">Bảo trì</span>
                </div>
                <div class="arrow-down" />
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="footer-reserve">
        <div class="footer-reserve--info container-custom">
          <div class="list-reserve">
            <nuxt-link
              title="DMCA.com Protection Status"
              target="_blank"
              to="//www.dmca.com/Protection/Status.aspx?ID=f362511c-0d21-4b76-af37-f633d9a377ba"
            >
              <BaseImg src="assets/images/components/desktop/footer/dmca.svg" class="dmca" alt="copy right" />
            </nuxt-link>
            <p class="reserve-text">{{ COPY_RIGHT }}</p>
            <BaseImg
              src="assets/images/components/desktop/footer/copyright-logo.png"
              class="reserve-image"
              alt="copy right"
            />
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script setup lang="ts">
import isEmpty from 'lodash/isEmpty'
import { storeToRefs } from 'pinia'
import { useAppStore } from '~~/store/app'
import { useAccountStore } from '~/store/account'
import { DEPOSIT_LIST, getFooterContent, getFooterNavigation } from '@/constants/footer'
import { MODAL_TYPES } from '~/config/constant'
import { PAGE_URLS } from '~/config/page-url'
import { useContact } from '~/composables/useContact'
import useModal from '~/composables/useModal'
import BranchInfo from '~~/components/common/branch-info.vue'

const { openModalWithNavigate } = useModal()
const router = useRouter()
const { $config, $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { currentUser, isLogged } = storeToRefs(store)
const accountStore: any = useAccountStore()
const { fetchBranchContact, contacts } = useContact()

interface FooterPaymentMethod {
  key: string;
  title: string;
  image: string;
  link: string;
  isMaintain: boolean;
  isShowTooltip: boolean;
}

onMounted(async () => {
  await fetchBranchContact()
})

const contactFooters = computed(() => {
  return contacts.value.filter((item) => !['facebook', 'fanpage'].includes(item.name.toLowerCase()))
})

const { COPY_RIGHT } = getFooterContent()
const { NAVIGATIONS } = getFooterNavigation()

const footerPaymentMethods = ref<FooterPaymentMethod[]>([])

const updateFooterPaymentMethods = () => {
  footerPaymentMethods.value = DEPOSIT_LIST.map((item) => {
    let isMaintain = false
    if (!accountStore.loading) {
      switch (item.key) {
        case 'cardList':
          isMaintain = !(
            accountStore.cardList?.VIETTEL?.status === 1 ||
            accountStore.cardList?.MOBIFONE?.status === 1 ||
            accountStore.cardList?.VINAPHONE?.status === 1 ||
            accountStore.cardList?.ZING?.status === 1
          )
          break
        case 'ViettelPays':
          isMaintain = accountStore.viettelPays?.length === 0
          break
        case 'nicepayBanks':
          isMaintain =
            (isEmpty(accountStore.providerGoPay) ||
              !accountStore.providerGoPay?.GOPAY?.bankings?.length) &&
            accountStore[item.key]?.length === 0
          break
        case 'crypto_v2':
          isMaintain = accountStore.crypto_v2?.[item.key]?.length === 0
          break
        case 'momos':
          isMaintain = !accountStore.momos || accountStore.momos.length === 0
          break
        default:
          break
      }
    }
    return {
      ...item,
      isMaintain
    }
  })
}

watch(
  () => [
    accountStore.cardList,
    accountStore.viettelPays,
    accountStore.providerGoPay,
    accountStore.crypto_v2,
    accountStore.momos,
    accountStore.loading
  ],
  () => {
    updateFooterPaymentMethods()
  },
  { deep: true }
)

onMounted(() => {
  updateFooterPaymentMethods()
})

const handleClickMethod = (item: any, event: any) => {
  if (currentUser.value === null) {
    openModalWithNavigate(MODAL_TYPES.LOGIN, 'login', item?.link)
  } else if (!item.isMaintain) {
    navigateTo(item?.link)
  } else {
    item.isShowTooltip = true
    setTimeout(() => {
      item.isShowTooltip = false
    }, 3000)
    event.preventDefault()
  }
}

const goHome = () => {
  router.push({ path: PAGE_URLS.HOME })
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}
</script>
<style lang="scss" scoped src="assets/scss/components/desktop/footer/index.scss" />
