<template>
  <div class="floating">
    <div v-for="(item, indexSubMenu) in contactList" :key="indexSubMenu" class="floating__item">
      <NuxtLink :to="item.url" target="_blank" class="floating__item--image">
        <CommonBaseImg v-if="!isBotChromeLighthouse" class="icon icon-bottom" :src="item.img" alt="icon" />
        <div class="tooltip">
          <div class="tooltip-content">
            <span class="description">{{ item?.display_name }}</span>
          </div>
          <div class="arrow-down" />
        </div>
      </NuxtLink>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useAppStore } from '~/store/app'
import { seoApi } from '~/api/seo'
import { PATH_ICON_URL } from '~/constants/path'
const CommonBaseImg = defineAsyncComponent(() => import('~/components/common/base-img.vue'))
const { $openLiveChat, $config, $axios, $pinia } = useNuxtApp()
const { isBotChromeLighthouse } = useAppStore()
const store = useAppStore($pinia)
const { BRAND_CONTACT } = seoApi()
const contactList = ref<any>([])
const fetchContact = async () => {
  try {
    const { data: response } = await $axios.get(BRAND_CONTACT)
    if (response && response.status === 'OK') {
      if (response.data?.length > 0) {
        contactList.value = response.data
        store.setContactData(contactList.value)
      }
    }
  } catch (error: any) {
    console.log('error', error?.response?.data?.message)
  }
}
onMounted(() => {
  setTimeout(async () => {
    await fetchContact()
  }, 200)
})
</script>
<style lang="scss" scoped src="assets/scss/components/desktop/floating-menu/index.scss" />
