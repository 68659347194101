<template>
  <div id="header" class="header">
    <div class="header__container container-custom">
      <div class="header-menu">
        <nuxt-link id="logo" class="logo" :to="PAGE_URLS.HOME">
          <BaseImg :src="LOGO_SITE_IMAGE" alt="logo" />
        </nuxt-link>
        <client-only>
          <div class="menu">
            <nuxt-link
              v-for="(item, index) in menuList"
              :id="`link-${item?.name}`"
              :key="index"
              :to="item?.activeUrl"
              class="menu__item"
              :class="[
                item?.name,
                {
                  openSubMenu: openSubMenu === item?.name,
                  active: isActiveHeader(item)
                }
              ]"
              @mouseover="onMouseOver(item)"
              @mouseleave="onMouseLeave()"
              @click="onHideMenu()"
            >
              <span class="menu-title">{{ item.title }}</span>
              <span v-if="item.subMenu" class="icon-menu-down" />
              <div v-if="item.subMenu && item.subMenu.length" class="sub-menu" :class="item?.name">
                <div class="sub-menu__container" :class="{ gridmenu: item.subMenu.length > 6 }">
                  <nuxt-link
                    v-for="(subItem, subIndex) in item.subMenu"
                    :id="`header-${subItem?.name}`"
                    :key="`${index}${subIndex}`"
                    class="sub-menu__item"
                    :class="[subItem?.type, subItem.alias, { actvice: isActive(subItem) }]"
                    :to="getFullPath(item, subItem)"
                    @click.prevent="onClickSubMenu(item, subItem)"
                  >
                    <div class="sub-menu__item--content" :class="{ active: isActive(subItem) }">
                      <BaseImg
                        class="icon-inactive"
                        :src="subItem?.icon?.inactive ?? subItem.icon"
                        :alt="subItem.display_name"
                      />
                      <div class="text">
                        <p>{{ subItem.display_name }}</p>
                        <div v-if="(subItem.jackpot || 0) > 0" class="jackpot">
                          <AnimateCountUp
                            v-if="subItem.jackpot !== 0"
                            :number="subItem.jackpot"
                            :show-coin="false"
                            class="value"
                          />
                        </div>
                      </div>
                    </div>
                    <div v-if="!subItem?.isNotShowLine" class="line" />
                  </nuxt-link>
                </div>
              </div>
            </nuxt-link>
          </div>
        </client-only>
      </div>
      <div class="header-button">
        <div id="notification-header" class="header-button__notif">
          <BaseImg
            id="bell"
            class="header-button__notif--bell"
            src="/assets/images/components/desktop/icon-notif.svg"
            alt="bell"
          />
        </div>
        <client-only>
          <UserNotLogin v-if="!currentUser" />
          <UserLogged v-else />
        </client-only>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { LOGO_SITE_IMAGE } from '~~/constants/menu'
import BaseImg from '~/components/common/base-img.vue'
import { useAppStore } from '@/store/app'
import { MENUS } from '~/constants/menu'
import UserNotLogin from '~/components/desktop/header/user-not-login.vue'
import UserLogged from '~/components/desktop/header/user-logged.vue'
import { IMenuHeader, ISubMenuHeader } from '~/types/menu.type'
import { useJackpot } from '~/composables/game/useJackpot'
import { useGameStore } from '~~/store/game'
import { LOBBY_CASINO_URLS, LOBBY_GAME_URLS, PAGE_URLS } from '~/config/page-url'
import { IProvider } from '~/types/lobby.type'
import { useGame } from '~/composables/game/useGame'
import { useCasino } from '~/composables/casino/useCasino'
import AnimateCountUp from '~~/components/common/animate-count-up.vue'

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot, currentUser, isLogged } = storeToRefs(store)
const jackpotNumber: Ref<number | null> = ref(null)
const { onSumJackpotInterval, onSumJackpotIntervalClear } = useJackpot()

const storeGame = useGameStore($pinia)
const { providerGames, providerCasino } = storeToRefs(storeGame)
const openSubMenu = ref('')
const { fetchCongGame } = useGame()
const { fetchGameCasino } = useCasino()
const route = useRoute()
const router = useRouter()
const onMouseOver = (item: IMenuHeader) => {
  if (item.subMenu && item.subMenu.length) {
    openSubMenu.value = item?.name
  }
}
const onMouseLeave = () => {
  openSubMenu.value = ''
}

const fetchData = () => {
  if (providerGames.value.length === 0) {
    fetchCongGame()
  }
  if (providerCasino.value.length === 0) {
    fetchGameCasino()
  }
}
const onClickMenu = (item: IMenuHeader) => {
  if (item.name === 'games' && item?.activeUrl === '/game') {
    if (isLogged.value) {
      router.push(
        LOBBY_GAME_URLS.ALL +
          '/' +
          (providerGames?.value?.length > 0 ? (providerGames?.value[0] as IProvider)?.alias : 'game-bai')
      )
    } else {
      router.push(
        LOBBY_GAME_URLS.ALL +
          '/' +
          (providerGames?.value?.length > 0 ? (providerGames?.value[1] as IProvider)?.alias : 'game-bai')
      )
    }
  } else if (item.name === 'casino' && item?.activeUrl === '/livecasino') {
    router.push(
      LOBBY_CASINO_URLS.ALL +
        '/' +
        (providerCasino?.value?.length > 0 ? (providerCasino?.value[0] as IProvider)?.alias : 'blackjack')
    )
  } else if (item?.activeUrl) {
    router.push(item?.activeUrl)
  }
}

const isActiveHeader = (item: any) => {
  const isValidLivecasinoPath = (path: string): boolean => {
    const regex = /^\/livecasino(\/[a-zA-Z0-9-]+)?(\?\w+=\w+)?$/
    return regex.test(path.trim())
  }

  if (['livecasino-type', 'livecasino'].includes(item?.type) && isValidLivecasinoPath(route.fullPath)) {
    return true
  }
  if (item?.name === route?.name && !(item?.name === 'game-type' && route?.params?.type === 'quay-so')) {
    return true
  }

  if (item?.type === route?.name || item?.activeUrl === route.fullPath) {
    return true
  }
  return false
}

const onHideMenu = () => {
  openSubMenu.value = ''
}

const isActive = (subItem: ISubMenuHeader) => {
  return (
    subItem.alias === route.params.type ||
    subItem.alias === route.params.id ||
    (subItem.alias === 'all' && !route.params.type && route.name === 'livecasino')
  )
}

const getFullPath = (item: IMenuHeader, subItem: ISubMenuHeader) => {
  if (subItem?.alias !== 'all') {
    return `/${item.activeUrl?.split('/')?.[1]}/${subItem.alias}`
  }
  return item.activeUrl
}

function onClickSubMenu(item: IMenuHeader, subItem: ISubMenuHeader) {
  openSubMenu.value = ''
  const mainUrl = item.activeUrl.split('/')[1]
  const subItemUrl = subItem?.alias
  if (subItemUrl === 'all') {
    return router.push(`/${mainUrl}`)
  }
  const link = `/${mainUrl}/${subItemUrl}`
  router.push(link)
}

onMounted(() => {
  fetchData()
  onSumJackpotInterval()
  jackpotNumber.value = sumJackpot.value?.jackpotNohu ?? null
})

onBeforeUnmount(() => {
  onSumJackpotIntervalClear()
})

const menuList = reactive(MENUS)

const getSumJackpot = (aliasMenu: string) => {
  switch (aliasMenu) {
    case 'no-hu': {
      return sumJackpot.value.jackpotNohu
    }
    case 'ban-ca': {
      return sumJackpot.value.jackpotFishing
    }
    case 'table-game': {
      return sumJackpot.value.jackpotIngame
    }
    default: {
      return 0
    }
  }
}

watchEffect(() => {
  const updateSubMenu = (submenu: ISubMenuHeader[], type: string) => {
    const targetItem = menuList.find((item) => item.type === type)
    if (targetItem && submenu?.length) {
      targetItem.subMenu = submenu.map((itemSub) => {
        return {
          ...itemSub,
          type,
          jackpot: getSumJackpot(itemSub.alias || '')
        }
      })
    }
  }

  const subMenusToUpdate = [
    { submenu: providerGames.value, type: 'game' },
    { submenu: providerCasino.value, type: 'livecasino' }
  ]
  subMenusToUpdate.forEach(({ submenu, type }) => updateSubMenu(submenu, type))
})
</script>

<style scoped lang="scss" src="assets/scss/components/desktop/header/index.scss"></style>
